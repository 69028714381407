import axios from "axios";
import { backendClient } from "./clients";

export async function syncInvoiceList(user, company, days, signal) {
  return backendClient.request({
    url: '/InvoiceList',
    method: 'post',
    data: {
      days,
      company,
      user,
    },
    signal,
    timeout: -1
  }).then(res => res.data);
};

export async function uploadInvoice(company, data, opts = null) {
  try {
    const { serial = null, isSelf = false, isCreditNote = false } = opts ?? {};

    const response = await backendClient
      .request({
        url: "/InvoiceUpload",
        method: "post",
        headers: {
          "Content-Type": "application/xml",
        },
        params: {
          company,
          serial,
          isSelf: isSelf ? 1 : undefined,
          type: isCreditNote ? "NC" : "FCT"
        },
        data: data,
      })
    return response.data;
  } catch (error) {
    return {
      status: "Upload Nereusit",
      errors: error?.response?.data?.message
    };
  }
}

// export async function validateInvoice(user, company, data) {
//   return backendClient
//     .request({
//       url: "/InvoiceValidation",
//       method: "post",
//       headers: {
//         "Content-Type": "application/xml",
//       },
//       params: {
//         user,
//         company,
//       },
//       data: data,
//     })
//     .then((res) => res.data)

//     .catch((err) => {
//       throw err;
//     });
// }

export async function getExchangeRates() {
  try {
    const response = await backendClient.request({
      url: "/GetExchangeRates",
      method: "post",
    });

    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      throw new Error("Request cancelled");
    }

    return {
      status: "Error fetching exchange rates",
      errors: error?.response?.data?.message || error.message,
    };
  }
}

export async function validateInvoice(company, data, opts) {
  try {
    const { isCreditNote = false } = opts ?? {};

    const response = await backendClient.request({
      url: "/InvoiceValidation",
      method: "post",
      headers: {
        "Content-Type": "application/xml"
      },
      params: {
        company,
        type: isCreditNote ? "NC" : "FCT",
      },
      data: data
    });

    return response.data;
  } catch (error) {
    return {
      status: "Invoice invalid",
      errors: error?.response?.data?.errors
    };
  }
}



export async function downloadInvoice(company, id, format) {
  return backendClient
    .request({
      url: "/InvoiceDownload",
      method: "post",
      params: {
        company,
        id,
        format
      },
      timeout: 0,
    })
    .then((res) => res.data);
}

export async function previewInvoice(company, invoice) {
  return backendClient
    .request({
      url: "/InvoiceDetails",
      method: "post",
      data: {
        company,
        invoice
      },
    })
    .then((res) => res.data);
}

export async function getInvoiceList({ company, filter, sort, page, pageSize, signal = null }) {
  try {
    const response = await backendClient.post('/ArchiveList', {
      company,
      filter,
      sort,
      page,
      pageSize,
    }, { signal });

    if (response?.status !== 200) {
      throw new Error(`Unexpected response status: ${response?.status}`);
    }

    const { archives, hasMore } = response.data;
    return { archives, hasMore };
  } catch (error) {
    if (axios.isCancel(error)) {
      throw new Error('Request cancelled');
    }

    const message = error.response?.data?.message || error.message;
    throw new Error(`Failed to get invoice list: ${message}`);
  }
}





export async function InvoiceExport(user, company, startDate, endDate, invoiceType, fileType, config = undefined) {
  return backendClient
    .request({
      url: "/ArchiveListExport",
      method: "post",
      data: {
        user,
        company,
        startDate,
        endDate,
        invoiceType,
        fileType,
        config
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error exporting invoice:", err);
      throw err;
    });
}


export async function sendMessage(invoice, company, message) {
  return backendClient
    .request({
      url: "/InvoiceMessage",
      method: "post",
      data: {
        invoice,
        message,
        company
      },
    })
    .then((res) => res.data);
}

export async function markInvoice(company, id, state = true) {
  return backendClient
    .request({
      url: "/InvoiceMark",
      method: "post",
      data: {
        company,
        invoice: id,
        state
      },
    })
    .then((res) => res.data);
}

export async function uploadEtransport(company, data) {
  try {
    const response = await backendClient.request({
      url: "ETransUpload",
      method: "post",
      headers: {
        "Content-Type": "application/xml",
      },
      data: data,
      params: {
        company,
      },
    });

    return response.data;
  } catch (error) {
    return {
      status: "Upload Nereusit",
      errors: error?.response?.data?.message,
    };
  }
}

export async function askForEtransport(company, days) {
  try {
    const response = await backendClient.request({
      url: "ETransDownload",
      method: "post",
      params: {
        company,
        days,
      },
    });

    return response.data;
  } catch (error) {
    return {
      status: "Eroare la download",
      errors: error?.response?.data?.message,
    };
  }
}

export async function getAllEtransport(company, pageSize, continuationToken) {
  try {
    const response = await backendClient.request({
      url: "ETransGetAllData",
      method: "post",
      params: {
        company,
        pageSize,
        continuationToken,
      },
    });

    return response.data;
  } catch (error) {
    return {
      status: "Eroare la răspuns",
      errors: error?.response?.data?.message,
    };
  }
}
